import { useState, useEffect, EffectCallback } from 'react';

// const isMobileDevice = () => (window.innerWidth <= 765)

const useMobile = () => {
  const isClient = typeof window === 'object';
  const [size, setSize] = useState(
    isClient
      ? { isMobile: window.innerWidth <= 765, width: window.innerWidth, height: window.innerHeight }
      : undefined
  );

  useEffect((): EffectCallback => {
    if (!isClient) {
      return () => console.log('useMobile hook is only available in browser');
    }
    const checkSize = () =>
      isClient
        ? {
            isMobile: window.innerWidth <= 765,
            width: window.innerWidth,
            height: window.innerHeight,
          }
        : undefined;
    const handleResize = () => setSize(checkSize);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isClient]);

  return size;
};

export { useMobile };
