import React, { FunctionComponent } from 'react';
import { ChatState } from '../hooks/webrtc';
import PeerName from './PeerName';

type PeerStatusProps = {
  etat: ChatState;
  peerName: string;
  mobile?: boolean;
};

const PeerStatus: FunctionComponent<PeerStatusProps> = ({ etat, peerName, mobile }) => {
  // Le peerName est de la forme IT-Italy-Man
  const splitedPeer = peerName.split('-');
  const countryCode = splitedPeer[0] || '';
  const country = splitedPeer[1] || '';
  const gender = splitedPeer[2] || 'Man';
  const colorStyle = { color: mobile ? 'white' : 'inherit' };
  switch (etat) {
    case ChatState.newPeerWithId:
      return mobile ? (
        <span style={colorStyle}>Disconnected from chat.</span>
      ) : (
        <span>You have disconnected from chat. Please press the "start" button to return.</span>
      );

    case ChatState.connexionResetByPeer:
    case ChatState.peerAvailableForChatWithoutCounterpart:
      return (
        <span style={colorStyle}>
          <i className="fas fa-search" /> Searching for partner
        </span>
      );

    case ChatState.peerWithAffectedCounterpart:
    case ChatState.peerNegociatingWebRTCChatWithCounterpart:
      return <span style={colorStyle}>Connecting to peer</span>;

    case ChatState.peerIsChatting:
      return mobile ? (
        <span>
          <small className="font-weight-light text-white">
            Warning: You are not allowed to stream pornography, nudity or vulgarity on this app.
            Please be polite or you will be banned
          </small>
        </span>
      ) : (
        <PeerName peerName={peerName} mobile={mobile} />
      );

    default:
    case ChatState.peerIsNotConnected:
    case ChatState.newPeerWithoutId:
      return <span>Connecting to peer</span>;
  }
};

export default PeerStatus;
