import React, { FunctionComponent } from 'react';

type SpinnerProps = {
  display: boolean;
};

const Spinner: FunctionComponent<SpinnerProps> = ({ display }) => {
  const spinnerWrapperStyle = {
    position: 'absolute',
    marginLeft: '-30px',
    marginTop: '-30px',
    color: '#ff506e',
    fontSize: '60px',
    top: '50%',
    left: '50%',
    display: display ? 'block' : 'none',
  } as React.CSSProperties;

  return (
    <div id="loading_div" style={spinnerWrapperStyle}>
      <i className="fa fa-spinner fa-pulse fa-fw" />
    </div>
  );
};

export default Spinner;
