import React, { useRef, useEffect, FunctionComponent } from 'react';
import emojis from '../style/emoji';
import { renderEmojiToHtml } from './Emoji';
import { IMessage } from '../hooks/webrtc';

type ChatPanelProps = {
  receivedMessages: IMessage[];
  mobile?: boolean;
};

const ChatPanel: FunctionComponent<ChatPanelProps> = ({ receivedMessages, mobile = false }) => {
  const textAreaRef = useRef<HTMLDivElement>();
  const nbMessages = (receivedMessages && receivedMessages.length) || 0;
  useEffect(() => {
    textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
  }, [receivedMessages]);

  const formatMessageLine = (m: IMessage, i: number, mobile: boolean = false) => {
    const htmlMessageWithEmoji = emojis.reduce(
      (htmlMessage: string, e: string) => htmlMessage.replace(e, renderEmojiToHtml(e)),
      m.message
    );
    const style = (sender: string) =>
      sender === 'You'
        ? { fontWeight: 700, color: '#9aa2ab', marginRight: '22px' }
        : { fontWeight: 700, color: '#e25906' };
    // Si le message ne contient que des <div> </div> et <br>, ne rien afficher
    if (m.message.replace('<div>', '').replace('</div>', '').replace('<br>', '').trim() == '')
      return null;

    if (mobile) {
      return (
        <div
          className={
            'chat_message_mobile_container chat_message_mobile_' +
            (m.sender === 'You' ? 'left' : 'right')
          }
        >
          <span dangerouslySetInnerHTML={{ __html: htmlMessageWithEmoji }} />
        </div>
      );
    }

    return (
      <div key={i}>
        <span style={style(m.sender)}>{m.sender} : </span>
        <span dangerouslySetInnerHTML={{ __html: htmlMessageWithEmoji }} />
      </div>
    );
  };

  return (
    <div
      className={mobile ? 'chat_panel_mobile' : 'w-100 bg-white flex-grow-1'}
      ref={textAreaRef}
      style={{
        border: 'none',
        overflowY: nbMessages > 3 ? 'scroll' : 'hidden',
        wordBreak: 'break-word',
        marginBottom: '60px',
      }}
    >
      {receivedMessages && receivedMessages.length
        ? receivedMessages.map((m: IMessage, i: number) => formatMessageLine(m, i, mobile))
        : null}
    </div>
  );
};

export default ChatPanel;
