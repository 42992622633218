import { useState, useCallback } from 'react';

const useCardRatio = (initialRatio: number): [number, (height: number, width: number) => void] => {
  const [aspectRatio, setAspectRatio] = useState(initialRatio);

  const calculateRatio = useCallback((height: number, width: number): void => {
    if (height && width) {
      const isLandscape = height <= width;
      const ratio = isLandscape ? width / height : height / width;

      setAspectRatio(ratio);
    }
  }, []);

  return [aspectRatio, calculateRatio];
};

/*
const useOffsets = (vWidth:number, vHeight:number, cWidth:number, cHeight:number) => {
    const [offsets, setOffsets] = useState({ x: 0, y: 0 });
  
    useEffect(() => {
      if (vWidth && vHeight && cWidth && cHeight) {
        const x = vWidth > cWidth
          ? Math.round((vWidth - cWidth) / 2)
          : 0;
  
        const y = vHeight > cHeight
          ? Math.round((vHeight - cHeight) / 2)
          : 0;
  
        setOffsets({ x, y });
      }
    }, [vWidth, vHeight, cWidth, cHeight]);
  
    return offsets;
  }
*/

export { useCardRatio };
