import React, { useState, useRef, useEffect, FunctionComponent } from 'react';
import useHover from '../hooks/useHover';
import Spinner from './Spinner';
import PeerCameraControls from './PeerCameraControls';
import PeerCameraControlsMobile from './PeerCameraControlsMobile';
import { ChatState } from '../hooks/webrtc';

type PeerCameraProps = {
  mediaStream: MediaStream;
  fullscreen: boolean;
  setFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  availableForChat: (available: boolean) => void;
  etat: ChatState;
  peerName?: string;
  appInitialized: boolean;
  mobile: boolean;
  setPreference?: (countryCode: string) => void;
  preference?: string;
  textChat: boolean;
};

const PeerCamera: FunctionComponent<PeerCameraProps> = ({
  mediaStream,
  fullscreen,
  setFullScreen,
  availableForChat,
  etat,
  peerName,
  appInitialized,
  mobile,
  setPreference,
  preference,
  textChat,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [hoverRef, isHovered] = useHover();
  const showPeerControls = mediaStream && isHovered;
  const lookingForPeer = etat >= 2 && etat <= 4;
  const [speakerVolume, setSpeakerVolume] = useState('0.5');

  useEffect(() => {
    if (mediaStream) {
      const volume = parseFloat(speakerVolume);
      const audioTrack = mediaStream.getAudioTracks()[0];

      if (volume < 0.05) {
        audioTrack.enabled = false;
        console.log('Peer audio is muted');
      } else {
        if (!audioTrack.enabled) {
          mediaStream.getAudioTracks()[0].enabled = true;
        }
        videoRef.current.volume = volume;
      }
    }
  }, [speakerVolume, mediaStream]);

  useEffect(() => {
    if (mediaStream && mediaStream.active && !textChat) {
      videoRef.current.srcObject = mediaStream;
    } else {
      videoRef.current.srcObject = null;
    }
  }, [mediaStream]);

  // Définition des styles selon l'option fullscreen
  const hauteurVideo = 170;
  const styleVideo = {
    objectFit: 'cover',
    objectPosition: '100% 100%',
    width: '100%',
    height: '100%',
  } as React.CSSProperties;
  const styleContainerMyCamera = {
    flexGrow: 1,
    flexBasis: `${hauteurVideo}px`,
    marginBottom: !mobile && '4px',
  };
  const hauteurContainer = fullscreen && !mobile ? '94vh' : '100%';

  return (
    <div ref={hoverRef} style={styleContainerMyCamera}>
      <div
        style={{
          height: hauteurContainer,
          background: 'black',
          width: '100%',
          position: 'relative',
          display: 'block',
        }}
      >
        <video ref={videoRef} style={styleVideo} autoPlay={true} />
        <Spinner display={lookingForPeer} />

        {!mobile && (
          <PeerCameraControls
            showPeerControls={showPeerControls}
            speakerVolume={speakerVolume}
            setSpeakerVolume={setSpeakerVolume}
            fullscreen={fullscreen}
            setFullScreen={setFullScreen}
            availableForChat={availableForChat}
            appInitialized={appInitialized}
          />
        )}

        {mobile && (
          <PeerCameraControlsMobile
            showPeerControls={appInitialized && etat === 5}
            speakerVolume={speakerVolume}
            setSpeakerVolume={setSpeakerVolume}
            availableForChat={availableForChat}
            appInitialized={appInitialized}
            setPreference={setPreference}
            preference={preference}
            etat={etat}
            peerName={peerName}
          />
        )}
      </div>
    </div>
  );
};

export default PeerCamera;
