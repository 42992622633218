import React, { FunctionComponent } from 'react';

type VideoButtonControlProps = {
  state: boolean;
  setState: (state: boolean) => void;
  iconOn: string;
  iconOff: string;
};

const VideoButtonControl: FunctionComponent<VideoButtonControlProps> = ({
  state,
  setState,
  iconOn,
  iconOff,
}) => (
  <button className="btn btn-link" onClick={() => setState(!state)}>
    <i className={state ? iconOn : iconOff} />
  </button>
);

export default VideoButtonControl;
