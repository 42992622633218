import React, { useState, FunctionComponent } from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'reactstrap';
import GenderSelector from './GenderSelector';

type SplashProps = {
  nbUsersOnline: number;
  mobile: boolean;
  gender: string;
  setGender: (gender: string) => void;
  setStart: () => void;
  setTermsAgreed: (agreed: boolean) => void;
  termsAgreed: boolean;
};

const Splash: FunctionComponent<SplashProps> = ({
  nbUsersOnline,
  gender,
  setGender,
  setStart,
  setTermsAgreed,
  termsAgreed,
  mobile,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipGenderOpen, setTooltipGenderOpen] = useState(false);

  const { t } = useTranslation();

  const handleAcceptTerms = () => {
    setTermsAgreed(!termsAgreed);
    setTooltipOpen(false);
  };

  const handleGenderSelection = (gender: string) => {
    console.log('handleGenderSelection:', gender);
    setTooltipGenderOpen(false);
    setGender(gender);
  };

  const handleStart = () => {
    if (termsAgreed && gender) {
      setStart();
    } else {
      !termsAgreed && setTooltipOpen(true);
      !gender && setTooltipGenderOpen(true);
    }
  };

  if (mobile) {
    return (
      <div className="z-index-middle position-absolute fixed-top d-flex flex-column align-items-center justify-content-center text-white bg-black h-100 px-1 pb-5 text-center">
        <h1 className="responsive-title">{t('WELCOME_TITLE')}</h1>
        <p className="responsive-subtitle">
          <Trans i18nKey="WELCOME_SUBTITLE">
            The unique vide random chat{' '}
            <span className="text-primary font-weight-bold">100% free</span> to meet nice people.
          </Trans>
        </p>
        <div className="d-flex flex-column align-items-center" style={{ width: '320px' }}>
          <p>
            {' '}
            <i className="fa fa-users" /> <b>{nbUsersOnline}</b> {t('WELCOME_USERS_ONLINE')}
          </p>

          <GenderSelector
            setGender={handleGenderSelection}
            tooltipOpen={tooltipGenderOpen}
            gender={gender}
          />

          <button
            type="button"
            className="btn btn-primary btn-lg mt-3 mb-3 w-100 font-weight-bold"
            onClick={handleStart}
          >
            <i className="fas fa-play pr-3" /> {t('WELCOME_MAIN_START_BUTTON')}
          </button>
        </div>

        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={termsAgreed}
            onChange={handleAcceptTerms}
            id="defaultCheck1"
          />
          <label className="form-check-label" htmlFor="defaultCheck1">
            <Trans i18nKey="WELCOME_CHECKBOX_TERMS_OF_USE" />
          </label>
        </div>
        <Tooltip placement="bottom-start" isOpen={tooltipOpen} target="defaultCheck1">
          <Trans i18nKey="WELCOME_CHECKBOX_TOOLTIP" />
        </Tooltip>
      </div>
    );
  }

  return (
    <div>
      <h1>{t('WELCOME_TITLE')}</h1>
      <p className="lead mb-5">
        <Trans i18nKey="WELCOME_SUBTITLE">
          The unique vide random chat{' '}
          <span className="text-primary font-weight-bold">100% free</span> to meet nice people.
        </Trans>
      </p>
      <div className="d-flex flex-column align-items-start" style={{ width: '320px' }}>
        <p>
          {' '}
          <i className="fa fa-users" /> <b>{nbUsersOnline}</b> {t('WELCOME_USERS_ONLINE')}
        </p>

        <GenderSelector
          setGender={handleGenderSelection}
          tooltipOpen={tooltipGenderOpen}
          gender={gender}
        />

        <button
          type="button"
          className="btn btn-primary btn-lg mt-3 mb-3 w-100 font-weight-bold"
          onClick={handleStart}
        >
          <i className="fas fa-play pr-3" />
          {t('WELCOME_MAIN_START_BUTTON')}
        </button>
      </div>

      <div className="form-check hidden">
        <input
          className="form-check-input"
          type="checkbox"
          checked={termsAgreed}
          onChange={handleAcceptTerms}
          id="defaultCheck1"
        />
        <label className="form-check-label" htmlFor="defaultCheck1">
          <Trans i18nKey="WELCOME_CHECKBOX_TERMS_OF_USE" />
        </label>
      </div>
      <Tooltip placement="top-start" isOpen={tooltipOpen} target="defaultCheck1">
        <Trans i18nKey="WELCOME_CHECKBOX_TOOLTIP" />
      </Tooltip>
    </div>
  );
};

export default Splash;
