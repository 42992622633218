import React, { useState, useRef, useEffect, FunctionComponent } from 'react';
import Measure from 'react-measure';
import { useCardRatio } from '../hooks/useCardRatio';
import { ContentRect } from 'react-measure';
import VideoButtonControl from './VideoButtonControl';

type MyCameraProps = {
  mediaStream: MediaStream;
  fullscreen: boolean;
  mobile: boolean;
};

const MyCamera: FunctionComponent<MyCameraProps> = ({ mediaStream, fullscreen, mobile }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [initialRender, setInitialRender] = useState(true);
  const [container, setContainer] = useState({ height: 0, width: 0 });
  const [aspectRatio, calculateRatio] = useCardRatio(1.33);

  const [videoOn, setVideoOn] = useState(true);
  const [microOn, setMicroOn] = useState(true);

  useEffect(() => {
    if (mediaStream) {
      mediaStream.getAudioTracks()[0].enabled = microOn;
      mediaStream.getVideoTracks()[0].enabled = videoOn;
      videoRef.current.srcObject = mediaStream;
    }
  }, [videoOn, microOn, mediaStream]);

  // Calcul de la disposition de la video
  // Si fullscreen ou premier rendu (sans dimensionnement) : objectFit:"cover", objectPosition:"100% 100%", width:"100%", height:"100%" (video rognée)
  // Sinon : placer la totalité de la video dans le carré, en ajoutant si besoin des bandes noires au-dessus et en-dessous
  const videoAffichableComplètement = container.width / container.height > aspectRatio;
  const hauteurVideo = videoAffichableComplètement
    ? container.height
    : container.width / aspectRatio;
  const largeurVideo = videoAffichableComplètement
    ? container.height * aspectRatio
    : container.width;
  const offsetHauteur = Math.round((container.height - hauteurVideo) / 2);
  const offsetLargeur = Math.round((container.width - largeurVideo) / 2);

  const styleVideo =
    fullscreen || initialRender || mobile
      ? ({
          objectFit: 'cover',
          objectPosition: '100% 100%',
          width: '100%',
          height: '100%',
        } as React.CSSProperties)
      : ({
          bottom: `${offsetHauteur}px`,
          left: `${offsetLargeur}px`,
          width: `${largeurVideo}px`,
          height: `${hauteurVideo}px`,
          textAlign: 'center',
          position: 'absolute',
        } as React.CSSProperties);
  const styleBarreControls = {
    padding: !mobile && '5px',
    bottom: 0,
    left: 0,
    width: `${container.width}px`,
    textAlign: 'center',
    position: 'absolute',
    backgroundColor: fullscreen ? null : 'rgba(0,0,0,0.5)',
  } as React.CSSProperties;

  function handleResize(contentRect: ContentRect) {
    // On subit le redimensionnement du container
    // Y a-t-il eu une modification de largeur ?
    if (container.width && Math.abs(container.width - contentRect.bounds.width) > 2) {
      setInitialRender(false); // va provoquer le changement de style appliqué à <video>
    }
    setContainer({
      height: contentRect.bounds.height,
      width: contentRect.bounds.width,
    });
  }

  function handleCanPlay() {
    // On calcule le ratio hauteur/largeur de la video
    calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
    videoRef.current.play();
  }

  const styleContainerMyCamera = fullscreen
    ? ({
        position: 'absolute',
        borderRadius: '150px',
        overflow: 'hidden',
        bottom: '50px',
        left: '20px',
        width: '200px',
        height: '200px',
        '-webkit-transform': 'scaleX(-1)',
        transform: 'scaleX(-1)',
      } as React.CSSProperties)
    : ({
        position: 'relative',
        flexGrow: 1,
        background: 'black',
        flexBasis: '170px',
        height: mobile && '100%',
        '-webkit-transform': 'scaleX(-1)',
        transform: 'scaleX(-1)',
      } as React.CSSProperties);

  return (
    <Measure bounds={true} onResize={handleResize}>
      {({ measureRef }) => (
        <div ref={measureRef} style={styleContainerMyCamera}>
          <video
            ref={videoRef}
            onCanPlay={handleCanPlay}
            style={styleVideo}
            autoPlay={true}
            playsInline={true}
            muted={true}
          />
          {mediaStream && (
            <div style={styleBarreControls}>
              <VideoButtonControl
                setState={setVideoOn}
                state={videoOn}
                iconOn="fas fa-video"
                iconOff="fas fa-video-slash"
              />
              <VideoButtonControl
                setState={setMicroOn}
                state={microOn}
                iconOn="fas fa-microphone"
                iconOff="fas fa-microphone-slash"
              />
            </div>
          )}
        </div>
      )}
    </Measure>
  );
};

export default MyCamera;
