import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useWebRtc, IMessage } from './hooks/webrtc';
import { useMobile } from './hooks/useMobile';
import './theme.scss';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './index.css';
import SplitPane from 'react-split-pane';

import Splash from './components/Splash';
// import Header from './components/Header';
import ActionBar from './components/ActionBar';
// import Footer from './components/Footer';
import PeerStatus from './components/PeerStatus';
import AllowSplash from './components/AllowSplash';
import MyCamera from './components/MyCamera';
import PeerCamera from './components/PeerCamera';
import ChatPanel from './components/ChatPanel';
import ChatInput from './components/ChatInput';
import './libs/i18n';
import { useTranslation } from 'react-i18next';
import { config } from './config/config';

const containerStyle = {
  width: '100%',
  height: '100vh',
  minHeight: '520px',
  maxHeight: '1200px',
  maxWidth: '1920px',
  position: 'relative',
  backgroundColor: 'rgb(48,43,63, .9)',
  margin: '0 auto',
} as React.CSSProperties;

const App: React.FC = () => {
  // Taille de l'écran
  const size = useMobile();

  // gestion des messages textes envoyés
  const [receivedMessages, setReceivedMessages] = useState([
    { sender: 'me', message: '' } as IMessage,
  ]);
  const [isTyping, setIstyping] = useState(false); // True si le peer est en train de composer un message.

  const addReceivedmessage = (sender: string, messageChat: string) =>
    setReceivedMessages((previousMessages) => [
      ...previousMessages,
      { sender, message: messageChat },
    ]);
  // Envoi d'un message
  const handleSendMessage = (m: string) => {
    sendMessage({
      sender: myPeerName.country + ' - ' + myPeerName.gender,
      message: m,
      isTyping: false,
    });
    addReceivedmessage('You', m);
  };
  // Notification que l'on commence à écrire
  const handleBeginToWrite = () =>
    sendMessage({
      sender: myPeerName.country + ' - ' + myPeerName.gender,
      message: '',
      isTyping: true,
    });

  // Réception d'un message
  const handleReceivedMessage = (m: IMessage) => {
    if (m.isTyping) {
      setIstyping(true);
    }
    if (m.message) {
      addReceivedmessage('Partner', m.message);
    }
  };

  // Rebasculer automatiquement à false l'état de typing au bout de 3 secondes
  useEffect(() => {
    isTyping && setTimeout(() => setIstyping(false), 3000);
  }, [isTyping]);

  // Gestion des streams p2p
  const [
    myStream,
    remoteStream,
    availableForChat,
    etat,
    ,
    myPeerName,
    setMyPeerName,
    remotePeerName,
    sendMessage,
    nbUsers,
    allowCamera,
    cameraAllowed,
    waitingForUserAuthorisationCamera,
  ] = useWebRtc(handleReceivedMessage);
  const gender = myPeerName.gender;
  const setGender = (g: string) => setMyPeerName({ type: 'SET_GENDER', gender: g });

  const myCountryGuessedByServer = myPeerName.countryCode;
  const preference = myPeerName.preference;
  const setPreference = (p: string) => setMyPeerName({ type: 'SET_PREFERENCE', preference: p });

  const [appInitialized, setAppInitialized] = useState(false);
  const [fullscreen, setFullScreen] = useState(false);
  const [textChat, setTextChat] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);

  // Si la préférence de pays n'est pas encore choisi par l'utilisateur, et que le serveur a deviné le pays de l'hote, alors l'intialiser
  if (myCountryGuessedByServer && !preference) setPreference(myCountryGuessedByServer);

  // Callback pour démarrer ou next
  const startOrNext = (disponibilite: boolean) => {
    if (!appInitialized) {
      setAppInitialized(true);
    }
    // Recherche d'un nouvel interlocuteur
    availableForChat(disponibilite);
  };

  const { t } = useTranslation();

  // A chaque changement d'état, on efface l'historique des messages
  useEffect(() => {
    setReceivedMessages([]);
  }, [etat]);

  useEffect(() => {
    // Si l'utilisateur a pris sa décision d'autoriser l'accès à sa caméra, on sort du mode text chat si on y était
    if (myStream && cameraAllowed && textChat && !waitingForUserAuthorisationCamera) {
      setTextChat(false);
    }
  }, [cameraAllowed, textChat, waitingForUserAuthorisationCamera, myStream]);

  // Mobile render
  if (size && size.width < 769) {
    return (
      <div className="d-flex flex-column align-items-stretch vh-100">
        <Helmet>
          <title>{t('HEAD_TITLE')}</title>
          <meta name="description" content={t('HEAD_DESCRIPTION')} />
          <meta property="og:title" content={t('HEAD_TITLE')} />
          <meta property="og:type" content="website" />
          <meta property="og:description" content={t('HEAD_DESCRIPTION')} />
          <meta property="og:image" content={`${config.website}/zoucam.jpg`} />
          <meta property="og:site_name" content="Zoucam" />
        </Helmet>
        <AllowSplash
          visible={
            !textChat && !cameraAllowed && appInitialized && !waitingForUserAuthorisationCamera
          }
          mobile={true}
          textChatOnly={() => {
            setTextChat(true);
            startOrNext(true);
          }}
        />
        {/*<Header />*/}
        {!appInitialized && (
          <Splash
            nbUsersOnline={nbUsers}
            gender={gender}
            setGender={setGender}
            mobile={true}
            termsAgreed={termsAgreed}
            setTermsAgreed={(s) => setTermsAgreed(s)}
            setStart={() => {
              setAppInitialized(true);
              allowCamera().then((autorisation) => autorisation && startOrNext(true));
            }}
          />
        )}
        <PeerCamera
          mediaStream={remoteStream}
          fullscreen={true}
          appInitialized={appInitialized && !waitingForUserAuthorisationCamera}
          mobile={true}
          setFullScreen={null}
          availableForChat={
            appInitialized
              ? startOrNext
              : () => {
                  setAppInitialized(true);
                  allowCamera().then((autorisation) => autorisation && startOrNext(true));
                }
          }
          etat={etat}
          peerName={remotePeerName}
          setPreference={setPreference}
          preference={preference}
          textChat={textChat}
        />
        {/* appInitialized && <div className="position-absolute" style={{top:'60px', left:'270px', color:'white'}} ><PeerStatus etat={etat} peerName={remotePeerName} /></div> */}
        {appInitialized && <ChatPanel receivedMessages={receivedMessages} mobile={true} />}
        <div className="d-flex flex-row">
          <div className="border border-white my_video">
            <MyCamera mediaStream={myStream} fullscreen={false} mobile={true} />
          </div>

          <div className="d-flex flex-column align-items-stretch px-1 justify-content-between chat-div">
            <ActionBar
              buttonStartEnable={termsAgreed && gender.length > 0}
              etat={etat}
              availableForChat={
                appInitialized
                  ? startOrNext
                  : () => {
                      setAppInitialized(true);
                      allowCamera().then((autorisation) => autorisation && startOrNext(true));
                    }
              }
              setPreference={setPreference}
              preference={preference}
              fullscreen={false}
              mobile={true}
              compactLayout={size.width < 400}
            />
            <ChatInput
              sendMessage={handleSendMessage}
              writing={handleBeginToWrite}
              peerIsWriting={isTyping}
              inactif={etat !== 5}
              mobile={true}
              compactLayout={size.width < 480}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-full d-flex flex-column align-items-stretch">
      <Helmet>
        <title>{t('HEAD_TITLE')}</title>
        <meta name="description" content={t('HEAD_DESCRIPTION')} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={t('HEAD_TITLE')} />
        <meta property="og:description" content={t('HEAD_DESCRIPTION')} />
        <meta property="og:image" content={`${config.website}/zoucam.jpg`} />
        <meta property="og:site_name" content="Zoucam" />
      </Helmet>
      <AllowSplash
        visible={
          !textChat && !cameraAllowed && appInitialized && !waitingForUserAuthorisationCamera
        }
        mobile={false}
        textChatOnly={() => {
          setTextChat(true);
          startOrNext(true);
        }}
      />
      <div style={containerStyle}>
        <SplitPane
          split="vertical"
          className="p-2"
          size={fullscreen ? '75%' : 400}
          minSize={330}
          maxSize={fullscreen ? 1000 : 800}
        >
          {/* Video panel */}
          <div className="h-100 d-flex flex-column">
            <PeerCamera
              mediaStream={remoteStream}
              appInitialized={appInitialized}
              mobile={false}
              fullscreen={fullscreen}
              setFullScreen={setFullScreen}
              availableForChat={startOrNext}
              etat={etat}
              textChat={textChat}
            />
            <MyCamera mediaStream={myStream} fullscreen={fullscreen} mobile={false} />
            <div className="separateur">
              <i className="fas fa-ellipsis-v" />
            </div>
          </div>

          {/* Chat Messages panel */}
          <div className="px-2 d-flex flex-column h-100">
            <ActionBar
              buttonStartEnable={termsAgreed && gender.length > 0}
              etat={etat}
              availableForChat={startOrNext}
              setPreference={setPreference}
              preference={preference}
              fullscreen={fullscreen}
              mobile={false}
            />

            <div className="bg-white d-flex justify-content-between flex-column flex-grow-1 p-2 msg_wrap">
              <div>
                {!appInitialized && (
                  <Splash
                    nbUsersOnline={nbUsers}
                    gender={gender}
                    setGender={setGender}
                    mobile={false}
                    termsAgreed={termsAgreed}
                    setTermsAgreed={(s) => setTermsAgreed(s)}
                    setStart={() => {
                      setAppInitialized(true);
                      allowCamera().then((autorisation) => autorisation && startOrNext(true));
                    }}
                  />
                )}
                {/* remotePeerName && <p>Chatting with : {remotePeerName} </p> */}
              </div>

              <div>
                {appInitialized && (
                  <small className="font-weight-light ">
                    Warning: You are not allowed to stream pornography, nudity or vulgarity on this
                    app. Please be polite or you will be banned
                  </small>
                )}
              </div>

              <div>
                {appInitialized && !waitingForUserAuthorisationCamera && (
                  <PeerStatus etat={etat} peerName={remotePeerName} />
                )}
              </div>

              {appInitialized ? (
                <ChatPanel receivedMessages={receivedMessages} />
              ) : (
                <div className="bg-white flex-grow-1" />
              )}

              <ChatInput
                sendMessage={handleSendMessage}
                writing={handleBeginToWrite}
                peerIsWriting={isTyping}
                inactif={etat !== 5}
                mobile={false}
              />
            </div>
          </div>
        </SplitPane>
      </div>

      {/*<Footer />*/}
    </div>
  );
};

export default App;
