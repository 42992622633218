import React, { FunctionComponent } from 'react';

type ReportModalProps = {
  sendReport: (from: string, report: string) => void;
};

const ReportModal: FunctionComponent<ReportModalProps> = ({ sendReport }) => (
  <div>
    <div className="mb-2">
      <a
        className="col small text-primary ml-2"
        href="#bannerformmodal"
        data-toggle="modal"
        data-target="#bannerformmodal"
      >
        Report bugs and issues
      </a>
    </div>

    <div
      className="modal fade bannerformmodal"
      role="dialog"
      aria-labelledby="bannerformmodal"
      aria-hidden="true"
      id="bannerformmodal"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Report bug and issues
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="form-group">
              <label>Email address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                aria-describedby="emailHelp"
                placeholder="Enter email"
              />
              <small id="emailHelp" className="form-text text-muted">
                We'll never share your email with anyone else.
              </small>
            </div>
            <div className="form-group">
              <label>Your report</label>
              <textarea
                rows={5}
                className="form-control"
                id="report"
                aria-describedby="reportHelp"
                placeholder="Enter report"
              />
              <small id="emailHelp" className="form-text text-muted">
                Describe the bug you've detected.
              </small>
            </div>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-primary">
              Send report
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ReportModal;
