import React, { FunctionComponent } from 'react';
import RemoteAudioVolume from './RemoteAudioVolume';
import FlagSelector from './FlagSelector';
import { ChatState } from '../hooks/webrtc';
import PeerName from './PeerName';
import PeerStatus from './PeerStatus';

type PeerCameraControlsMobileProps = {
  showPeerControls: boolean;
  speakerVolume: string;
  setSpeakerVolume: (volume: string) => void;
  availableForChat: (available: boolean) => void;
  appInitialized: boolean;
  setPreference?: (countryCode: string) => void;
  preference?: string;
  etat: ChatState;
  peerName: string;
};

const PeerCameraControlsMobile: FunctionComponent<PeerCameraControlsMobileProps> = ({
  showPeerControls,
  speakerVolume,
  setSpeakerVolume,
  availableForChat,
  appInitialized,
  setPreference,
  preference,
  etat,
  peerName,
}) => {
  return (
    <>
      <div className="peer-camera-controls-mobile" style={{ display: !showPeerControls && 'none' }}>
        <RemoteAudioVolume
          speakerVolume={speakerVolume}
          setSpeakerVolume={setSpeakerVolume}
          mobile={true}
        />
        &nbsp;
        <FlagSelector
          setPreference={setPreference}
          preference={preference}
          fullscreen={false}
          mobile={true}
        />
        &nbsp;
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={() => availableForChat(true)}
        >
          <i className="fas fa-flag" />
        </button>
        &nbsp;
        <PeerName peerName={peerName} mobile={true} />
      </div>

      {appInitialized && (
        <div style={{ position: 'absolute', bottom: '10px', left: '10px', right: '10px' }}>
          <PeerStatus etat={etat} peerName={peerName} mobile={true} />
        </div>
      )}

      {appInitialized && (
        <div style={{ bottom: '45%', right: 0, position: 'absolute' }}>
          <div
            style={{
              zIndex: 1000,
              fontSize: '90px',
              background: '0 0',
              color: '#ff506e',
              border: 'none',
            }}
            onClick={() => availableForChat(true)}
          >
            <i className="fas fa-angle-right" />
          </div>
        </div>
      )}
    </>
  );
};

export default PeerCameraControlsMobile;
