import React, { useState, useRef, useCallback, FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReportModal from './ReportModal';
import { CustomEmoji, renderEmojiToHtml } from './Emoji';
import ContentEditable from 'react-contenteditable';
import emojis from '../style/emoji';
import useComponentVisible from '../hooks/useComponentVisible';

type ChatInputProps = {
  sendMessage: (message: string) => void;
  writing: () => void;
  inactif: boolean;
  peerIsWriting: boolean;
  mobile: boolean;
  compactLayout?: boolean;
};

const roundedStyle = (left: boolean) => ({
  borderTopLeftRadius: left ? '0.25rem' : '0',
  borderBottomLeftRadius: left ? '0.25rem' : '0',
  borderTopRightRadius: !left ? '0.25rem' : '0',
  borderBottomRightRadius: !left ? '0.25rem' : '0',
});

const ChatInput: FunctionComponent<ChatInputProps> = ({
  sendMessage,
  writing,
  inactif,
  peerIsWriting,
  mobile,
  compactLayout,
}) => {
  const refContentEditable = useRef<HTMLDivElement>(null);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [message, setMessage] = useState('');

  const addEmoji = (emoji: string) => {
    const emojiHtml = renderEmojiToHtml(emoji);
    setMessage((m) => m + emojiHtml);
    setIsComponentVisible(false);
    refContentEditable.current.focus();
  };

  const handleSendMessage = useCallback(() => {
    // Si le message est un simple retour chariot, on le skip
    const messageSansBR = message.replace('<div><br></div>', '');
    if (messageSansBR === '') return;
    sendMessage(messageSansBR);
    setMessage('');
  }, [message]);

  useEffect(() => {
    if (message.includes('<div><br></div>')) {
      handleSendMessage();
    }
  }, [message]);

  const { t } = useTranslation();

  return (
    <>
      {!mobile && (
        <ReportModal sendReport={(from: string, report: string) => console.log(from, report)} />
      )}

      <div className="chat_input">
        {peerIsWriting && (
          <div className="rlt-chat-typing">
            <span className="tc-dot" />
            <span className="tc-dot" />
            <span className="tc-dot" />
          </div>
        )}

        <div className="ci_iw">
          <div className="form-control emoji-container" style={roundedStyle(true)}>
            <ContentEditable
              className="emoji-wysiwyg"
              disabled={inactif}
              innerRef={refContentEditable}
              html={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={() => writing()}
            />
          </div>

          <div className="chat-emoji" onClick={() => setIsComponentVisible((s) => !s)}>
            <i className="far fa-smile" />
          </div>
        </div>

        <div
          ref={ref}
          className="card"
          style={{
            display: isComponentVisible ? 'block' : 'none',
            position: 'absolute',
            bottom: mobile ? '10px' : '60px',
            right: '10px',
            width: '250px',
            zIndex: 1000,
          }}
        >
          {emojis.map((e, i) => (
            <button key={i} type="button" className="btn btn-lg p-0" onClick={() => addEmoji(e)}>
              {' '}
              <CustomEmoji emoji={e} />{' '}
            </button>
          ))}
        </div>

        <button
          type="button"
          id="send_btn"
          className="form-control"
          style={roundedStyle(false)}
          disabled={inactif}
          onClick={handleSendMessage}
        >
          {compactLayout ? (
            <i className="fa fa-chevron-right chat-icon-button" />
          ) : (
            <b>{t('CHAT_INPUT_BUTTON')}</b>
          )}
        </button>
      </div>
    </>
  );
};

export default ChatInput;
