import { useEffect, useState, useReducer } from 'react';
import io from 'socket.io-client';
import SimplePeer from '../simple-peer';

const SIGNALING_SERVER_URL =
  process.env.NODE_ENV === 'production' ? 'https://api.zoucam.com' : 'http://localhost:3000';

const socket = io(SIGNALING_SERVER_URL, { transports: ['websocket'], path: '/socket' });

let peerConnexion: SimplePeer.Instance | null = null;

export interface IMessage {
  sender: string;
  message?: string;
  isTyping?: boolean;
}

export interface ICountry {
  country: string;
  countryCode: string;
}

export interface IProfil {
  country: string;
  countryCode: string;
  gender: string;
  preference: string;
}

export interface InitChatPayload {
  peerId: string;
}

export interface BeginChatPayload {
  peerName: string;
  initiator: boolean;
}

export interface IActionPeerNameReducer {
  type: string;
  country?: string;
  gender?: string;
  preference?: string;
  countryCode?: string;
}

// Etats possibles :
export enum ChatState {
  newPeerWithoutId = 0, // 0 : je suis en attente de mon id attribué par le serveur
  newPeerWithId, // 1 : le peer accuse réception de son id. Il attend que l'utilisateur se déclare disponible au chat
  peerAvailableForChatWithoutCounterpart, // 2 : disponible au chat, en attente de proposition de correspondant par un evt 'begin_chat'
  peerWithAffectedCounterpart, // 3 : le serveur lui a trouvé un interlocuteur avec qui discuter
  peerNegociatingWebRTCChatWithCounterpart, // 4 : le peer négocie la connexion avec l'interlocuteur attribué par le serveur
  peerIsChatting, // 5 : le peer est en discussion. Si il zappe, et retourne dans l'état 2
  peerIsNotConnected, // 6 : le peer s'est déconnecté
  connexionResetByPeer, // 7 : le peer a mis fin à la discussion
}

const peerNameReducer = (state: IProfil, action: IActionPeerNameReducer): IProfil => {
  switch (action.type) {
    case 'SET_COUNTRY':
      return { ...state, country: action.country || '', countryCode: action.countryCode || '' };
    case 'SET_GENDER':
      return { ...state, gender: action.gender || '' };
    case 'SET_PREFERENCE':
      return { ...state, preference: action.preference || '' };
    default:
      return state;
  }
};

// Custom webrtc hook
const useWebRtc = (
  messageHandler: (message: IMessage) => void
): [
  MediaStream,
  MediaStream,
  (available: boolean) => void,
  ChatState,
  string,
  IProfil,
  React.Dispatch<IActionPeerNameReducer>,
  string,
  (message: IMessage) => void,
  number,
  () => Promise<boolean>,
  boolean,
  boolean
] => {
  const [myStream, setMyStream] = useState<MediaStream | null>(null); // Mon stream audio video émis vers mon interlocuteur
  const [mediaError, setMediaError] = useState(''); // Message d'erreur si mon stream ne paut pas être acquis
  const [peer, setPeer] = useState<SimplePeer.Instance | null>(null); // Instance SimplePeer

  const [peerId, setPeerId] = useState(''); // Mon identifiant unique transmis par le serveur
  const [myProfil, setMyProfil] = useReducer(peerNameReducer, {
    country: '',
    countryCode: '',
    gender: '',
    preference: '',
  }); // Mon Pays (renvoyé par le serveur) + mon genre (saisi dans le front-end avant le début de connexion) + ma préférence d'interlocuteur (saisi dans le frontent)

  const [remoteStream, setRemoteStream] = useState<MediaStream | null>(null); // Stream audio video de mon interlocuteur
  const [remotePeerName, setRemotePeerName] = useState(''); // pseudo de mon interlocuteur

  const [etat, setEtat] = useState(ChatState.newPeerWithoutId);
  const [availability, setAvailability] = useState(false);
  const [endOfConnexion, setEndOfConnexion] = useState(false);

  const [nbUsers, setNbUsers] = useState(0);
  const [cameraAllowed, setCameraAllowed] = useState(false);
  const [waitingForUserAuthorisationCamera, setWaitingForUserAuthorisationCamera] = useState(true); // temporisation pour différer l'apparition de la pop-up de demande d'accès à la caméra
  const differedSetWaitingForUserAuthorisationCamera = (value: boolean) =>
    setTimeout(() => setWaitingForUserAuthorisationCamera(value), 2000);

  // Demander l'accès à la caméra
  const allowCamera = async () => {
    const options = { video: true, audio: true };
    differedSetWaitingForUserAuthorisationCamera(false);
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // differedSetWaitingForUserAuthorisationCamera(false)
      return navigator.mediaDevices
        .getUserMedia(options)
        .then((stream) => {
          setCameraAllowed(true);
          setMyStream(stream);
          return true;
        })
        .catch((e) => {
          setMediaError('Refus accès à la caméra.');
          setCameraAllowed(false);
          setMyStream(null);
          return false;
        });
    } else {
      setCameraAllowed(false);
      setMediaError('Ce navigateur est incompatible avec la vidéo peer-to-peer.');
      setMyStream(null);
      return false;
    }
  };

  // Afficher les erreurs éventuelles d'accès à la camera
  if (mediaError) console.error(mediaError);

  // On informe le serveur des changements d'état à la main de l'utilisateur
  useEffect(() => {
    console.log('Emission evt availability :', { peerName: myProfil, state: etat });
    socket && socket.emit('availability', { peerName: myProfil, state: etat }); // will remove any active connexion
  }, [etat, myProfil]);

  // Si mon stream est disponible, je le visualise dans la fenêtre de retour.
  // Et si une connexion p2p est établie, je l'ajoute
  useEffect(() => {
    peer && myStream && peer.addStream(myStream);
    // Affichage du retour vidéo sans le son
    // myStream && bindVideoToHtmlElement(myStream, '#myVideo');
  }, [myStream, peer]);

  const signalPeerConnexion = (webRTCdata: any) => {
    // console.log("prise en compte du signal reçu :", webRTCdata);
    // console.log("peerConnexion :", peerConnexion);
    if (peerConnexion && webRTCdata) peerConnexion.signal(webRTCdata);
  };

  // On ecoute les evts de la socket lorsqu'on est connecté
  useEffect(() => {
    // Le serveur m'affecte un id suite à ma demande, mais ne me place pas encore dans le pool des interlocuteurs disponibles
    socket &&
      socket.on('init_chat', (initChatData: InitChatPayload) => {
        console.log('Réception evt init_chat ', initChatData);
        // Le serveur me donne mon identifiant mon identifiant
        setPeerId(initChatData.peerId);
        // Je lui indique que je suis connecté, mais non encore disponible pour démarrer le chat
        setEtat(ChatState.newPeerWithId);
      });

    // Le serveur m'indique le pays détecté et son code
    socket &&
      socket.on('detected_country', (params: ICountry) => {
        console.log(params);
        console.log('Detected country : ', params.country, params.countryCode);
        setMyProfil({
          type: 'SET_COUNTRY',
          country: params.country,
          countryCode: params.countryCode,
        });
      });

    // Modification du nom du peer avec lequel je converse
    socket &&
      socket.on('update_peername', (params: { conversationPeerName: string }) => {
        console.log('update_peername :', params.conversationPeerName);

        setRemotePeerName(params.conversationPeerName);
      });

    // Le serveur s'est déconnecté ou demande une ré-initialisation
    socket &&
      socket.on('disconnect', () => {
        console.log('Perte de la connexion avec le serveur');
        setEtat(ChatState.newPeerWithoutId);
      });
    socket &&
      socket.on('reset', () => {
        console.log('Perte de la connexion avec le serveur');
        setEtat(ChatState.newPeerWithoutId);
      });

    // Le peer a mis fin à la discussion
    socket &&
      socket.on('end_chat', () => {
        console.log('Fin de la discussion avec le peer');
        setRemoteStream(null);
        setEtat(ChatState.peerAvailableForChatWithoutCounterpart);
      });

    // Indication du nb d'utilisateurs
    socket &&
      socket.on('users_online', (users: number) => {
        setNbUsers(users);
      });

    // Je suis sollicité par le serveur pour démarrer un chat
    socket &&
      socket.on('begin_chat', (initChatData: BeginChatPayload) => {
        console.log('Réception evt begin_chat ', initChatData);

        // Pseudo de mon interlocuteur
        setRemotePeerName(initChatData.peerName); // This is the peer who wants to chat

        // Configuration de la nouvelle connexion webRTC
        console.log("Création d'une instannce SimplePeer");
        peerConnexion = new SimplePeer({ initiator: initChatData.initiator, trickle: false });

        // On s'échange les WebRTC data au travers des sockets
        peerConnexion.on('signal', (webRTCdata: any) => socket.emit('webrtc_data', webRTCdata));

        peerConnexion.on('connect', () => {
          console.log('Je suis connecté au peer !');
          myStream && peerConnexion && peerConnexion.addStream(myStream);
          // Signaler que la convesation est établie
          setEtat(ChatState.peerIsChatting);
          setPeer(peerConnexion);
        });

        // Arrivée d'un message chat
        peerConnexion.on('data', (data: Buffer) => messageHandler(JSON.parse(data.toString())));

        // Arrivée d'un flux vidéo
        peerConnexion.on('stream', (stream: MediaStream) => {
          console.log('setRemoteStream:', stream);
          // Si j'ai autorisé ma caméra, je peux recevoir un stream
          setRemoteStream(stream);
          // bindVideoToHtmlElement(stream, '#peerVideo');
        });

        // Si la connexion est interrompue
        peerConnexion.on('close', () => {
          console.log('Connexion au peer fermée');
          setEndOfConnexion(true);
        });

        peerConnexion.on('error', (error) => {
          console.error('Erreur de connexion', { error });
          setEndOfConnexion(true);
        });

        // Signaler que je suis en train d'établir une connexion webRTC
        setEtat(ChatState.peerNegociatingWebRTCChatWithCounterpart);
      });

    socket && socket.on('webrtc_data', (webRTCdata: any) => signalPeerConnexion(webRTCdata));
  }, []);

  // Envoi d'un message texte
  const sendMessage = (message: IMessage) => {
    if (peerConnexion && etat === ChatState.peerIsChatting) {
      peerConnexion.send(JSON.stringify(message));
    }
  };

  // Me déclarer dispo pour un chat
  const availableForChat = async (available: boolean) => {
    available && console.log('Je me déclare disponible au chat');
    await setAvailability(available);

    // Fin de la discussion éventuellement en cours
    peerConnexion && (await peerConnexion.destroy());
    peerConnexion = null;
    await setPeer(null);
    await setRemotePeerName('');
    await setRemoteStream(null);

    if (available) {
      // Démarrer la caméra si elle n'est pas déjà active
      if (myStream === null) await allowCamera();
      // Ré-initiliser la détection de perte de connexion
      setEndOfConnexion(false);
      // Informer le serveur de me proposer quelqu'un
      setEtat(ChatState.peerAvailableForChatWithoutCounterpart);
    } else {
      // Informer le serveur que je ne suis plus disponible
      setEtat(ChatState.newPeerWithId);
    }
  };

  // Gestion de la connexion perdue
  useEffect(() => {
    // Y-a-til une fin de connexion subie alors que je suis dispo au chat ?
    if (availability && endOfConnexion) availableForChat(true);
  }, [availability, endOfConnexion]);

  return [
    myStream,
    remoteStream,
    availableForChat,
    etat,
    peerId,
    myProfil,
    setMyProfil,
    remotePeerName,
    sendMessage,
    nbUsers,
    allowCamera,
    cameraAllowed,
    waitingForUserAuthorisationCamera,
  ];
};

export { useWebRtc };
