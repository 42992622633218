import React, { FunctionComponent } from 'react';

const styleAllowSplash = {
  camerahint: (mobile: boolean) =>
    ({
      background: 'rgba(0,0,0,.9)',
      backgroundImage: 'url(hello-girl.png)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: mobile ? 'left bottom' : 'center bottom',
      color: '#fff',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 10000,
    } as React.CSSProperties),
  textchat: {
    textAlign: 'left',
    position: 'absolute',
    left: '40%',
    bottom: '40px',
    maxWidth: '420px',
    zIndex: 1,
  } as React.CSSProperties,
  bannerallow: (mobile: boolean) =>
    ({
      position: 'absolute',
      top: mobile ? '50px' : '200px',
      left: mobile ? '10px' : '420px',
    } as React.CSSProperties),
};

type AllowSplashProps = {
  visible: boolean;
  mobile: boolean;
  textChatOnly?: () => void;
};

const AllowSplash: FunctionComponent<AllowSplashProps> = ({ visible, mobile, textChatOnly }) => (
  <div style={{ ...styleAllowSplash.camerahint(mobile), display: visible ? 'block' : 'none' }}>
    {mobile ? (
      <div style={styleAllowSplash.textchat}>
        <strong>Don't feel like turning on your cam? Try text chat instead</strong>
        <p>
          <button className="btn btn-primary mt-2" onClick={() => textChatOnly && textChatOnly()}>
            Start Text Chat
          </button>
        </p>
      </div>
    ) : (
      <div style={styleAllowSplash.bannerallow(mobile)}>
        <div>
          <h4>
            Allow camera{' '}
            <img
              style={{ width: '100px' }}
              src="https://static.omecam.com/img/ch-eyes.svg"
              alt="Allow camera"
            />{' '}
            access in your browser.
          </h4>
          <p>
            For the <span style={{ color: '#07d1c5' }}>most exciting</span> experience, enable your
            camera so your partner can see you!
          </p>
          <strong>Don't feel like turning on your cam? Try text chat instead</strong>
          <p>
            <button className="btn btn-primary mt-2" onClick={() => textChatOnly && textChatOnly()}>
              Start Text Chat
            </button>
          </p>
        </div>
      </div>
    )}
  </div>
);

export default AllowSplash;
