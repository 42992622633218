import React, { FunctionComponent } from 'react';
import Gender from './Gender';

type PeerNameProps = {
  peerName: string;
  mobile?: boolean;
};

const PeerName: FunctionComponent<PeerNameProps> = ({ peerName, mobile }) => {
  // Le peerName est de la forme IT-Italy-Man
  const splitedPeer = peerName.split('-');
  const countryCode = splitedPeer[0] || '';
  const country = splitedPeer[1] || '';
  const gender = splitedPeer[2] || 'Man';
  const colorStyle = { color: mobile ? 'white' : 'inherit' };

  return (
    <div style={colorStyle}>
      {countryCode && <span className={'flag flag-' + countryCode.toLowerCase()} />}
      {country} &nbsp;
      <Gender gender={gender} withLabel={false} />
    </div>
  );
};

export default PeerName;
