import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      HEAD_TITLE: 'Zoucam - The best free random video chat',
      HEAD_DESCRIPTION:
        'With Zoucam, multiply your meetings and your relationships with the Zoucam webcam chatroulette. Adored by young girls and boys, it is the only free dating site by camera and without registration on the internet.',
      WELCOME_TITLE: 'Welcome to Zoucam',
      WELCOME_SUBTITLE: `The unique vide random chat <1>100% free</1> to meet nice people.`,
      WELCOME_MAIN_START_BUTTON: `Start !`,
      WELCOME_START_BUTTON: `Start !`,
      WELCOME_USERS_ONLINE: 'Users online.',
      WELCOME_CHECKBOX_TERMS_OF_USE: 'I have read, and agree to be bound by the Terms of Use.',
      WELCOME_CHECKBOX_TOOLTIP: 'You must agree to continue.',
      ACTION_BAR_START: 'Start',
      ACTION_BAR_NEXT: 'Next',
      ACTION_BAR_STOP: 'Stop',
      CHAT_INPUT_BUTTON: 'SEND',
      FLAG_SELECTOR_BUTTON_TITLE: 'Country',
      GENDER_BUTTON_TITLE: 'Select your gender',
      GENDER_SELECTION_WOMAN: 'Woman',
      GENDER_SELECTION_MAN: 'Man',
      GENDER_SELECTION_COUPLE: 'Couple',
      GENDER_SELECTOR_TITLE: 'Select your gender',
      GENDER_SELECTOR_SELECTION_WOMAN: 'Woman',
      GENDER_SELECTOR_SELECTION_MAN: 'Man',
      GENDER_SELECTOR_SELECTION_COUPLE: 'Couple',
    },
  },
  fr: {
    translation: {
      HEAD_TITLE: 'Zoucam - Le meilleur chatroulette gratuit',
      HEAD_DESCRIPTION:
        "Avec Zoucam, multipliez vos rencontres et vos relations avec le chatroulette webcam Zoucam. Adoré des jeunes filles et garçons, c'est l'unique site de rencontre par caméra gratuit et sans inscription sur internet.",
      WELCOME_TITLE: 'Bienvenue sur Zoucam',
      WELCOME_SUBTITLE: `Le seul chatroulette alternatif <1>100% gratuit</1> pour faire des rencontres.`,
      WELCOME_MAIN_START_BUTTON: `Démarrer !`,
      WELCOME_START_BUTTON: `Démarrer !`,
      WELCOME_USERS_ONLINE: 'Utilisateurs en ligne',
      WELCOME_CHECKBOX_TERMS_OF_USE:
        "Je certifie avoir lu et accepté les conditions d'utilisation.",
      WELCOME_CHECKBOX_TOOLTIP: 'Vous devez accepter les conditions afin de poursuivre.',
      ACTION_BAR_START: 'Démarrer',
      ACTION_BAR_NEXT: 'Suivant',
      ACTION_BAR_STOP: 'Stop',
      CHAT_INPUT_BUTTON: 'ENVOYER',
      FLAG_SELECTOR_BUTTON_TITLE: 'Pays',
      GENDER_BUTTON_TITLE: 'Selectionnez votre sexe',
      GENDER_SELECTION_WOMAN: 'Femme',
      GENDER_SELECTION_MAN: 'Homme',
      GENDER_SELECTION_COUPLE: 'Couple',
      GENDER_SELECTOR_TITLE: 'Selectionnez votre sexe',
      GENDER_SELECTOR_SELECTION_WOMAN: 'Femme',
      GENDER_SELECTOR_SELECTION_MAN: 'Homme',
      GENDER_SELECTOR_SELECTION_COUPLE: 'Couple',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'fr',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false,
    },
  });

export { i18n };
