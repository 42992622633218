import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'reactstrap';
import Gender from './Gender';
import useComponentVisible from '../hooks/useComponentVisible';

type GenderSelectorProps = {
  tooltipOpen: boolean;
  gender: string;
  setGender: (gender: string) => void;
};

const GenderSelector: FunctionComponent<GenderSelectorProps> = ({
  tooltipOpen,
  gender,
  setGender,
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const { t } = useTranslation();

  return (
    <div
      ref={ref}
      id="genderselector"
      className="gender-select"
      onClick={() => setIsComponentVisible((c) => !c)}
    >
      <Tooltip placement="top-start" isOpen={tooltipOpen} target="genderselector">
        {t('GENDER_SELECTOR_TITLE')}
      </Tooltip>

      <Gender gender={gender} withLabel={true} />
      <ul style={{ display: isComponentVisible ? 'block' : 'none' }}>
        <li>
          <label className="trns" onClick={() => setGender('Man')}>
            <em className="fa fa-mars" /> {t('GENDER_SELECTOR_SELECTION_MAN')}
          </label>
        </li>
        <li>
          <label className="trns" onClick={() => setGender('Woman')}>
            <em className="fa fa-venus" /> {t('GENDER_SELECTOR_SELECTION_WOMAN')}
          </label>
        </li>
        <li>
          <label className="trns" onClick={() => setGender('Couple')}>
            <em className="fa fa-venus-mars" /> {t('GENDER_SELECTOR_SELECTION_COUPLE')}
          </label>
        </li>
      </ul>
    </div>
  );
};

export default GenderSelector;
