import React, { FunctionComponent, useState } from 'react';

type RemoteAudioVolumeProps = {
  speakerVolume: string;
  setSpeakerVolume: (volume: string) => void;
  mobile?: boolean;
};

const RemoteAudioVolume: FunctionComponent<RemoteAudioVolumeProps> = ({
  speakerVolume,
  setSpeakerVolume,
  mobile,
}) => {
  const [speakerMuted, setSpeakerMuted] = useState(speakerVolume === '0.0');
  const toggleMute = (s: boolean) => {
    if (s) {
      setSpeakerVolume('0.5');
      return false;
    } else {
      setSpeakerVolume('0.0');
      return true;
    }
  };
  if (mobile)
    return (
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={() => setSpeakerMuted(toggleMute)}
      >
        {parseFloat(speakerVolume) < 0.05 ? (
          <i className="fa fa-volume-mute" aria-hidden="true" />
        ) : (
          <i className="fa fa-volume-up" aria-hidden="true" />
        )}
      </button>
    );

  return (
    <div className="remaud_wrp trns">
      <div id="remote_audio_button" onClick={() => setSpeakerMuted(toggleMute)}>
        {parseFloat(speakerVolume) < 0.05 ? (
          <i className="fa fa-volume-mute" aria-hidden="true" />
        ) : (
          <i className="fa fa-volume-up" aria-hidden="true" />
        )}
      </div>
      <input
        id="audio_range"
        type="range"
        min="0"
        max="1"
        step="0.1"
        value={speakerVolume}
        onChange={(e) => setSpeakerVolume(e.target.value)}
      />
    </div>
  );
};

export default RemoteAudioVolume;
