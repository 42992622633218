import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import FlagSelector from './FlagSelector';

type ActionBarProps = {
  buttonStartEnable: boolean;
  etat: number;
  setPreference: (countryCode: string) => void;
  preference: string;
  availableForChat: (ready: boolean) => void;
  fullscreen: boolean;
  mobile: boolean;
  compactLayout?: boolean;
};

const ActionBar: FunctionComponent<ActionBarProps> = ({
  availableForChat,
  setPreference,
  preference,
  buttonStartEnable,
  etat,
  fullscreen,
  mobile,
  compactLayout,
}) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-row py-1 justify-content-between flex-wrap z-index-front">
      <div className={'d-flex flex-row flex-wrap ' + (mobile ? 'w-100' : '')}>
        {!fullscreen && (
          <div
            className={mobile ? (compactLayout ? 'mb-1 w-100' : ' mb-1 w-50 pr-2') : 'mr-2 mb-1'}
          >
            <button
              type="button"
              disabled={!buttonStartEnable}
              className={
                mobile
                  ? compactLayout
                    ? 'btn btn-sm btn-primary w-100'
                    : 'btn btn-lg btn-primary w-100'
                  : 'btn btn-lg btn-primary'
              }
              onClick={() => availableForChat(true)}
            >
              <b>
                <i className="fas fa-play-circle" />{' '}
                {etat < 2 ? t('ACTION_BAR_START') : t('ACTION_BAR_NEXT')}
              </b>
            </button>
          </div>
        )}

        <div className={mobile ? (compactLayout ? 'w-100' : ' mb-1 w-50') : 'mb-1'}>
          <button
            type="button"
            disabled={etat < 2}
            className={
              mobile
                ? compactLayout
                  ? 'btn btn-danger btn-sm w-100'
                  : 'btn btn-lg btn-danger w-100'
                : 'btn btn-lg btn-danger largeur-fixe-bouton'
            }
            onClick={() => availableForChat(false)}
          >
            <i className="far fa-stop-circle" /> {t('ACTION_BAR_STOP')}
          </button>
        </div>
      </div>

      {!mobile && (
        <FlagSelector
          setPreference={setPreference}
          preference={preference}
          fullscreen={fullscreen}
        />
      )}
    </div>
  );
};

export default ActionBar;
