import React, { FunctionComponent } from 'react';
import VideoButtonControl from './VideoButtonControl';
import RemoteAudioVolume from './RemoteAudioVolume';
import { config } from '../config/config';

type PeerCameraControlsProps = {
  showPeerControls: boolean;
  speakerVolume: string;
  setSpeakerVolume: (volume: string) => void;
  fullscreen: boolean;
  setFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  availableForChat: (available: boolean) => void;
  appInitialized: boolean;
};

const PeerCameraControls: FunctionComponent<PeerCameraControlsProps> = ({
  showPeerControls,
  speakerVolume,
  setSpeakerVolume,
  fullscreen,
  setFullScreen,
  availableForChat,
  appInitialized,
}) => {
  return (
    <>
      <div
        style={{
          borderRadius: '4px',
          top: '10px',
          left: '10px',
          position: 'absolute',
          background: 'black',
          display: showPeerControls ? 'block' : 'none',
        }}
      >
        <RemoteAudioVolume speakerVolume={speakerVolume} setSpeakerVolume={setSpeakerVolume} />
      </div>

      <div
        style={{
          padding: '10px 20px',
          bottom: '0%',
          left: fullscreen ? '90%' : '0%',
          position: 'absolute',
          display: showPeerControls ? 'block' : 'none',
        }}
      >
        <VideoButtonControl
          setState={setFullScreen}
          state={fullscreen}
          iconOff="fas fa-expand-arrows-alt"
          iconOn="fas fa-compress-arrows-alt"
        />
      </div>

      <div style={{ borderRadius: '4px', top: '10px', right: '10px', position: 'absolute' }}>
        <a href={config.website}>
          <img src="logo.png" alt="Zoucam" style={{ height: '2.2em', width: 'auto' }} />
        </a>
      </div>

      <div
        style={{
          bottom: '50%',
          left: '45%',
          position: 'absolute',
          display: showPeerControls ? 'block' : 'none',
        }}
      >
        <button type="button" className="btn btn-primary" onClick={() => availableForChat(true)}>
          <i className="fas fa-flag" /> Report
        </button>
      </div>

      {fullscreen && appInitialized && (
        <div style={{ bottom: '45%', right: 0, position: 'absolute' }}>
          <div
            style={{
              zIndex: 1000,
              fontSize: '90px',
              background: '0 0',
              color: '#ff506e',
              border: 'none',
            }}
            onClick={() => availableForChat(true)}
          >
            <i className="fas fa-angle-right" />
          </div>
        </div>
      )}
    </>
  );
};

export default PeerCameraControls;
