import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

type GenderProps = {
  gender: string;
  withLabel: boolean;
};

const Gender: FunctionComponent<GenderProps> = ({ gender, withLabel = true }) => {
  const { t } = useTranslation();

  switch (gender) {
    case 'Woman':
      return (
        <span>
          <em className="fa fa-venus" /> {withLabel ? t('GENDER_SELECTION_WOMAN') : ''}
        </span>
      );
    case 'Couple':
      return (
        <span>
          <em className="fa fa-venus-mars" /> {withLabel ? t('GENDER_SELECTION_COUPLE') : ''}{' '}
        </span>
      );
    case 'Man':
      return (
        <span>
          <em className="fa fa-mars" /> {withLabel ? t('GENDER_SELECTION_MAN') : ''}{' '}
        </span>
      );
    default:
      return <span>{t('GENDER_BUTTON_TITLE')}</span>;
  }
};

export default Gender;
