import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import useComponentVisible from '../hooks/useComponentVisible';

/*  Liste des codes : https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 */
const availableCountries = [
  { code: 'am', label: 'Armenia' },
  { code: 'ar', label: 'Argentina' },
  { code: 'at', label: 'Austria' },
  { code: 'au', label: 'Australia' },
  { code: 'az', label: 'Azerbaijan' },
  { code: 'be', label: 'Belgium' },
  { code: 'bg', label: 'Bulgaria' },
  { code: 'br', label: 'Brazil' },
  { code: 'ca', label: 'Canada' },
  { code: 'ch', label: 'Switzerland' },
  { code: 'cl', label: 'Chile' },
  { code: 'cn', label: 'China' },
  { code: 'co', label: 'Colombia' },
  { code: 'de', label: 'Germany' },
  { code: 'dz', label: 'Algeria' },
  { code: 'eg', label: 'Egypt' },
  { code: 'es', label: 'Spain' },
  { code: 'fr', label: 'France' },
  { code: 'gb', label: 'United Kingdom' },
  { code: 'gr', label: 'Greece' },
  { code: 'hr', label: 'Croatia' },
  { code: 'hu', label: 'Hungary' },
  { code: 'id', label: 'Indonesia' },
  { code: 'ie', label: 'Ireland' },
  { code: 'il', label: 'Israel' },
  { code: 'in', label: 'India' },
  { code: 'iq', label: 'Iraq' },
  { code: 'it', label: 'Italy' },
  { code: 'jo', label: 'Jordan' },
  { code: 'ma', label: 'Morocco' },
  { code: 'mx', label: 'Mexico' },
  { code: 'nl', label: 'Netherlands' },
  { code: 'nz', label: 'New Zealand' },
  { code: 'ph', label: 'Philippines' },
  { code: 'pk', label: 'Pakistan' },
  { code: 'pl', label: 'Poland' },
  { code: 'pt', label: 'Portugal' },
  { code: 'ro', label: 'Romania' },
  { code: 'rs', label: 'Serbia' },
  { code: 'ru', label: 'Russia' },
  { code: 'se', label: 'Sweden' },
  { code: 'tn', label: 'Tunisia' },
  { code: 'tr', label: 'Turkey' },
  { code: 'ua', label: 'Ukraine' },
  { code: 'us', label: 'United States' },
];

type FlagProps = {
  code: string;
  label: string;
  setPreference: (countryCode: string) => void;
  preference: string;
};

const Flag: FunctionComponent<FlagProps> = ({ code, setPreference, preference, label }) => {
  return (
    <li
      className={'flag flag-' + code.toLowerCase() + (code === preference ? ' active' : '')}
      onClick={() => setPreference(code)}
    >
      {label}
    </li>
  );
};

type FlagSelectorProps = {
  setPreference: (countryCode: string) => void;
  preference: string;
  fullscreen?: boolean;
  mobile?: boolean;
};

const FlagSelector: FunctionComponent<FlagSelectorProps> = ({
  setPreference,
  preference,
  fullscreen = false,
  mobile = false,
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const handleSelectCountry = (code: string) => {
    setIsComponentVisible(false);
    setPreference(code);
  };

  const { t } = useTranslation();

  return (
    <div className="cntr_wrap" style={{ marginBottom: mobile && '0px' }}>
      {mobile ? (
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={() => setIsComponentVisible((s) => !s)}
        >
          {!preference || preference === '' || preference === 'All' ? (
            <span id="cntr_pref">All</span>
          ) : (
            <span id="cntr_pref" className={'flag flag-' + preference.toLowerCase()} />
          )}
        </button>
      ) : (
        <button
          type="button"
          className="btn btn-lg text-white border-white"
          onClick={() => setIsComponentVisible((s) => !s)}
        >
          {t('FLAG_SELECTOR_BUTTON_TITLE')}:{' '}
          {!preference || preference === '' || preference === 'All' ? (
            <span id="cntr_pref">All</span>
          ) : (
            <span id="cntr_pref" className={'flag flag-' + preference.toLowerCase()} />
          )}
        </button>
      )}

      <div
        ref={ref}
        className={mobile ? 'cl_wrap_mobile' : 'cl_wrap'}
        style={{ display: isComponentVisible ? 'block' : 'none' }}
      >
        <ul id="cntr_lst">
          <li id="all_cntr" onClick={() => handleSelectCountry('All')}>
            <span>all countries</span>
          </li>
          {availableCountries.map((c) => (
            <Flag
              key={c.code}
              code={c.code.toUpperCase()}
              label={c.label}
              setPreference={handleSelectCountry}
              preference={preference}
            />
          ))}
        </ul>
        <div id="close_cl" onClick={() => setIsComponentVisible(false)}>
          <i className="fa fa-times" />
        </div>
      </div>
    </div>
  );
};

export default FlagSelector;
