import React, { FunctionComponent } from 'react';
import emojis from '../style/emoji';

type CustomEmojiProps = {
  emoji: string;
};

const CustomEmoji: FunctionComponent<CustomEmojiProps> = ({ emoji }) => {
  const index = emojis.indexOf(emoji);

  if (index > -1) {
    const colonne = Math.floor(index / 6) * 37 + 3.5;
    const ligne = (index % 6) * 37 + 3.5;
    const style = { backgroundPosition: '-' + ligne + 'px -' + colonne + 'px' };
    return (
      <div style={{ margin: '4px', float: 'left', height: '30px', width: '30px' }}>
        {' '}
        <span className="emoji-lst" style={style} />{' '}
      </div>
    );
  } else {
    return <span />;
  }
};

const renderEmojiToHtml = (emoji: string) => {
  const index = emojis.indexOf(emoji);

  if (index > -1) {
    const colonne = Math.floor(index / 6) * 24 + 2;
    const ligne = (index % 6) * 24 + 2;
    const style = `background-position: -${ligne}px -${colonne}px`;
    return `<img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" class="emoji" style="width: 20px; height: 20px;${style};background-size: 144px 144px;" >`;
  } else {
    return '';
  }
};

export { CustomEmoji, renderEmojiToHtml };
